import React from 'react'
import { Link } from 'gatsby'

import '../../static/styles/pagination.css'

export default ({ pageContext, pathFromRoot }) => {
  const toPath = pathFromRoot ? pathFromRoot : ''
  const nextArrow = (
    <span className="arrow-container d-flex align-items-center justify-content-end">
      <span style={{ marginRight: '28px', display: 'inline-block' }}>Next</span>
      <svg
        width={12}
        height={21}
        viewBox="0 0 12 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: '19px' }}
      >
        <path d="M1 1L10 10.5L1 20" stroke="#545FF5" strokeWidth={2} />
      </svg>
    </span>
  )
  const prevArrow = (
    <span className="arrow-container d-flex align-items-center">
      <svg
        width={12}
        height={21}
        viewBox="0 0 12 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: '19px' }}
      >
        <path d="M11 1L2 10.5L11 20" stroke="#545FF5" strokeWidth={2} />
      </svg>
      <span style={{ marginLeft: '28px', display: 'inline-block' }}>
        Previous
      </span>
    </span>
  )

  return (
    <div className="row justify-content-between align-items-center pagination-container">
      <div className="order-1 col-6 col-md-2 text-left">
        {pageContext.currentPage > 1 ? (
          <Link
            to={
              pageContext.currentPage - 1 === 1
                ? toPath
                : `${toPath}/${pageContext.currentPage - 1}`
            }
            rel="prev"
            className="relative-page-link"
          >
            {prevArrow}
          </Link>
        ) : (
          <span className="relative-page-link disabled">{prevArrow}</span>
        )}
      </div>
      <div className="order-3 order-md-2 col-12 col-md-auto">
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-center m-0">
            {Array.from({ length: pageContext.numberOfPages }).map((_, i) => (
              (
                i === 0 // Always show first post
                ||
                i + 1 === pageContext.numberOfPages // Always show last page
                ||
                (
                  (
                    i < pageContext.currentPage + 2 // Show next 2 pages
                    ||
                    i - 6 < 0 // If page is less than 6, show at least till page 6
                  )
                  &&
                  (
                    i + 2 > pageContext.currentPage - 2 // Show last 2 pages
                    || 
                    i + 2 > pageContext.numberOfPages - 5 // If on last page, show 5 pages
                  )
                )
              )
              &&
              <li
                key={i}
                className={
                  pageContext.currentPage === i + 1
                    ? 'page-item active'
                    : 'page-item'
                }
              >
                <Link
                  to={i === 0 ? toPath : `${toPath}/${i + 1}`}
                  className={`
                    page-link
                    ${i === 0 || i + 1 === pageContext.numberOfPages ? 'large-page-link' : ''}
                  `}
                >
                  <span>
                    {
                      i === 0 ? 'First' :
                      i + 1 === pageContext.numberOfPages ? 'Last' :
                      i + 1
                    }
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="order-2 order-md-3 col-6 col-md-2 text-right">
        {pageContext.currentPage < pageContext.numberOfPages ? (
          <Link
            to={`${toPath}/${pageContext.currentPage + 1}`}
            rel="next"
            className="relative-page-link"
          >
            {nextArrow}
          </Link>
        ) : (
          <span className="relative-page-link disabled">{nextArrow}</span>
        )}
      </div>
    </div>
  )
}
