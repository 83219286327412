import React, { useState, useEffect } from "react"
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"
import get from 'lodash/get'

import { Link } from 'gatsby'

import Author from './Author'

import Image from "utils/Image"
import '../../static/styles/top-post-preview.css'

const TopPostPreview = ({ node }) => {
  const [previewStory, setPreviewStory] = useState(null);

  let story = useStoryblokState(node, {
    resolveRelations: ["post.author", "post.categories"]
  });

  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      setPreviewStory(null);
    }
  }, [story]);

  useEffect(() => {
    if (window.location.search.includes('_storyblok')) {
      const url = `https://api-us.storyblok.com/v2/cdn/stories/${node.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_TOKEN}&cv=${Date.now()}&resolve_relations=post.author,post.categories`;

      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          setPreviewStory(json.story);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }
  }, []);

  const content = previewStory ? previewStory.content : story.content;

  return (
    <div id={node.slug} className="post-preview top-post-card d-flex flex-column">
      <div className="post-img-container">
        <Link to={`/${node.slug}`}>
          <div
            style={{
              width: '100%',
              height: '266px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row !important',
              backgroundClip: 'border-box',
            }}
          >
            <div className="image-wrapper">
              <Image img={content.hero} />
            </div>
          </div>
        </Link>
      </div>
      <div className="preview-contents flex-grow-1 d-flex flex-column">
        <div className="pre-title-group">
          <span className="date float-right">
            {content.published_date
              ? new Date(content.published_date).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
              : node.published_at}
          </span>
          {content.categories.slice(0, 3).map((category) => (
            <span className="post-category d-inline-flex" key={category.slug}>
              {category.name}
            </span>
          ))}
        </div>
        <h3>
          <Link to={`/${node.slug}`}>{get(node, 'name')}</Link>
        </h3>
        {/* <div className="flex-grow-1">
          <p
            dangerouslySetInnerHTML={{
              __html: node.metadata.description,
            }}
          />
          <Link to={`/${node.slug}`} className=''link arrow-link>
            Continue Reading
          </Link>
        </div> */}
        <div
          style={{
            margin: 'auto 0 0',
          }}
        >
          <Author node={content.author} />
        </div>
      </div>
    </div>
  )
}

export default TopPostPreview
