import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from "components/Seo"

import Blog from '../components/Blog'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.posts.edges')
    const pageContext = get(this, 'props.pageContext')

    return (
      <>
        <Seo
          title="Blog | QuickMail"
          description="Learn the best industry tips and tricks on how to increase your conversion through cold emails."
          url={"/blog"}
        />
        <Blog posts={posts} pageContext={pageContext} />
      </>
    )
  }
}

export default BlogIndex

export const BlogIndexQuery = graphql`query BlogIndexQuery($skip: Int!, $limit: Int!) {
  posts: allStoryblokEntry(
    filter: {
      field_component: { eq: "post" }
      field_hidden_boolean: { ne: true }
    }
    sort: {order: [DESC], fields: [published_at]}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        content
        slug
        name
        published_at(formatString: "MMMM D, YYYY")
      }
    }
  }
}
`
